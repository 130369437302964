import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, lastValueFrom } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { IIdentityDto } from './iidentity-dto';

@Injectable({
    providedIn: 'root'
})
export class IdentityController {

    constructor(private http: HttpClient) { }

    public async synchronizeUserAsync(): Promise<void> {
        return await lastValueFrom(this.http.patch<any>(this.endPoint('synchronizeUser'), null));
    }

    public async GetIdentityAsync(): Promise<IIdentityDto>{
        return await lastValueFrom(this.http.get<any>(this.endPoint('')).pipe(share()));
    }

    public async HasPermissionAsync(permission: string): Promise<boolean> {
        let params = new HttpParams();
        if (permission) { params = params.append('permissionCode', permission); }
        return await lastValueFrom(this.http.get<boolean>(this.endPoint('hasPermission'), { params }).pipe(share()));
    }

    public async GetUserAsync(): Promise<string> {
        return await lastValueFrom(this.http.get<any>(this.endPoint('getExternal')).pipe(share()));
    }

    public async GetRoleAsync(): Promise<string> {
        return await lastValueFrom(this.http.get<string>(this.endPoint('getRole')).pipe(share()));
    }

    public async IsBlockedAsync(): Promise<boolean> {
        return await lastValueFrom(this.http.get<boolean>(this.endPoint('isBlocked')).pipe(share()));
    }

    private endPoint(action: string): string {
        return environment.apiBaseUrl + 'identity/' + action;
    }
}
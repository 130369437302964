import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() uid: string;
  @Input() headerText: string;
  @Input() value: number;
  @Input() colour = 'primary';
  @Input() isBusy = true;
  @Input() icon: string;

  constructor() { }
}

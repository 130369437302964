import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { IGroup } from 'src/app/types/igroup';

@Component({
  selector: 'app-confirmation-modal-dialog',
  templateUrl: './confirmation-modal-dialog.component.html',
  styleUrls: ['./confirmation-modal-dialog.component.css']
})
export class ConfirmationModalDialogComponent implements OnInit {

  @Input() public statusDetails;
  @Input() public firmwareError: boolean = false;

  public newGroupId;

  public groupList = Array<IGroup>();
  public fields: Object = { text: 'name', value: 'id' };

  constructor(
    private userService: UserService,
    public statusModel: NgbActiveModal
  ) { }

  public get activeModal() {
    return this.statusModel;
  }

  ngOnInit(): void {
    if (this.statusDetails.title == 'Delete User Group') {
      this.userService.getUserGroups().subscribe(
        groups => {
          this.groupList = groups.filter(group =>
            group.groupTypeName === this.statusDetails.details?.groupTypeName);
          this.groupList = this.groupList.filter(group =>
            group.name !== this.statusDetails.details?.groupName);
            this.groupList.sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {}
      );
    }
  }

  public confirmClick()
  {
  }
}

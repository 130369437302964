import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(private userService: UserService,
    private titleService: Title) {
    this.titleService.setTitle("Page Not Found");
   }

  ngOnInit(): void {
  }

  public logOut() {
    this.userService.logout();
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AvailabilityComponent } from '../app/views/availability/availability.component';

import { AccessDeniedComponent } from './accessDenied/access-denied.component';
import { BlockedComponent } from './blocked/blocked.component';
import { AuthorizeGuard } from './guards/authorize.guard';
import { NotfoundComponent } from './notfound/notfound.component';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule), canLoad: [AuthorizeGuard] },
  { path: 'availability', component: AvailabilityComponent },
  { path: 'notfound', component: NotfoundComponent },
  { path: 'blocked', component: BlockedComponent },
  { path: 'accessdenied', component: AccessDeniedComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

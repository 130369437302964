<div>
  <div class='modal-header'>
    <h5 class='modal-title font-weight-bold m-0' id='modal-basic-title'>{{ this.statusDetails.title }}</h5>
    <button type='button' class='close' aria-label='Close' (click)='activeModal.dismiss()'>
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body'>
    <p>{{ this.statusDetails.message }}</p>
    <div *ngIf="this.statusDetails.details">
      <ul>
        <li *ngFor="let item of this.statusDetails.details | keyvalue">
          <strong>{{item.key}}:</strong> {{item.value}}
        </li>
      </ul>
    </div>
    <div *ngIf="this.statusDetails.title == 'Delete User Group' && this.statusDetails.details.numberOfUsers > 0">
      <ejs-dropdownlist placeholder="New Group" cssClass="e-outline" [dataSource]='groupList' [fields]='fields'
                    id="newGroup" [(ngModel)]='newGroupId' name="newGroup"></ejs-dropdownlist>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="this.statusDetails.title == 'Delete User Group' && this.statusDetails.details.numberOfUsers > 0; then deleteGroup else otherModal"></ng-container>
      
    
    <div *ngIf="!this.firmwareError">
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>

<ng-template #otherModal>
    <button ejs-button cssClass="e-info" id="btnConfirm" name="confirm"
            class="btn-text" (click)="confirmClick(); this.activeModal.close(true);">
        Confirm
    </button>
</ng-template>
<ng-template #deleteGroup>
  <button ejs-button cssClass="e-info" id="btnConfirm" name="confirm" [disabled]="!newGroupId"
            class="btn-text" (click)="confirmClick(); this.activeModal.close(newGroupId);">
        Confirm
    </button>
</ng-template>

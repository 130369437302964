import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
})
export class AvailabilityComponent implements OnInit {

  eName = environment.environmentName;

  constructor() { }

  ngOnInit(): void {
  }
}

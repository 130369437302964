import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { MsalModule, MsalInterceptor, MsalService, MsalBroadcastService, MsalGuard, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MenuModule } from '@syncfusion/ej2-angular-navigations';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

import { environment } from 'src/environments/environment';

import { SharedModule } from '../app/views/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AccessDeniedComponent } from './accessDenied/access-denied.component';
import { BlockedComponent } from './blocked/blocked.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { BytePipe } from './pipes/byte.pipe';
import { FirmwareBlobPipe } from './pipes/firmware-blob.pipe';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { ErrorHandlerService } from './services/error-handler.service';
import { AddHeaderInterceptor } from './services/logging.service';
import { UserService } from './services/user.service';
import { AvailabilityComponent } from './views/availability/availability.component';
import { UtilitiesComponent } from './views/utilities/utilities.component';

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msalConfig.consentScopes
    },
  };
}

function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(environment.authConfig);
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiBaseUrl, environment.msalConfig.consentScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({ declarations: [
        AppComponent,
        AvailabilityComponent,
        BytePipe,
        NotfoundComponent,
        FirmwareBlobPipe,
        UtilitiesComponent,
        BlockedComponent,
        HtmlToTextPipe,
        AccessDeniedComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        MenuModule,
        MsalModule,
        SharedModule,
        NgbModule,
        BrowserAnimationsModule,
        TextBoxModule,
        ButtonModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}

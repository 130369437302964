import { CacheLocation } from 'msal';

import { AppConstants } from '../app/app-constants';

export const environment = {
  production: true,
  appName: 'Admin',
  title: 'Admin Portal',
  environmentName: 'UAT',
  apiBaseUrl: 'https://app-webapi-uat.azurewebsites.net/api/',
  simulatorApiBaseUrl: 'https://app-websimulator-uat.azurewebsites.net',
  useMockComponents: false,
  authConfig: {
    auth: {
      clientId: '929e307a-44d1-4d58-be0a-f0ead6953b29',
      authority: 'https://glendimplexadb2c.b2clogin.com/tfp/glendimplexadb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp',
      redirectUri: 'https://adminportal-uat.gdhv-iotdevtest.com/',
      postLogoutRedirectUri: 'https://adminportal-uat.gdhv-iotdevtest.com/',
      navigateToLoginRequestUrl: true,
      validateAuthority: true,
      knownAuthorities: ['https://glendimplexadb2c.b2clogin.com/tfp/glendimplexadb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp', 
      'https://glendimplexadb2c.b2clogin.com/tfp/glendimplexadb2c.onmicrosoft.com/B2C_1_AdminPortal_PasswordReset']
    },
    cache: {
      cacheLocation: ('localStorage' as CacheLocation),
      storeAuthStateInCookie: AppConstants.isIE,
    },
  },
  msalConfig: {
    popUp: false,
    consentScopes: [
      'https://glendimplexadb2c.onmicrosoft.com/webapiuat/read'
    ],
    unprotectedResources: [],
    protectedResourceMap: ([
      ['https://app-webapi-uat.azurewebsites.net/', ['https://glendimplexadb2c.onmicrosoft.com/webapiuat/read']]
    ] as [string, string[]][]),
    extraQueryParameters: {}
  },
  b2cPolicies: {
    authorities: {
      signUpSignIn: {
        name: 'B2C_1_AdminPortal_SignIn_SignUp',
        authority: 'https://glendimplexadb2c.b2clogin.com/tfp/glendimplexadb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp'
      },
      passwordReset: {
        name: 'B2C_1_AdminPortal_PasswordReset',
        authority: 'https://glendimplexadb2c.b2clogin.com/tfp/glendimplexadb2c.onmicrosoft.com/B2C_1_AdminPortal_PasswordReset'
      },
    },
  },
  map: {
    urlTemplate: 'https://tile.openstreetmap.org/level/tileX/tileY.png'
  },
  appInsights: {
    instrumentationKey: 'a4c09f7f-ea34-4f35-80ba-16162c3f6ad9',
    connectionString: 'InstrumentationKey=a4c09f7f-ea34-4f35-80ba-16162c3f6ad9'
  },
  products: {
    dimplexWifi: '1FC4EEC5-628E-4195-ACE9-588FD031A679',
    intuisOfrWifi: 'B942990D-FF3F-4BC8-B3BE-F1575F1FFF1E',
    mullerPhWifi: 'BF1123D5-A164-41B7-8845-2253CB8C1156',
    optiflameV2: '002A104C-BCFD-4AAB-8347-BA21175C0B45',
    optiflameV3: 'C629B1B1-D6CD-4987-A243-126F7D06E106',
    optiflameV4: 'F1395F22-15DA-46AD-9EDC-F0809708D085'
  },
  reporting: {
    dashboardUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item='Dashboard'!A1%3AAB38&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    firmwareUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item='Firmware%20Stats'!D21%3AS39&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    totalVsOnlineHubsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=Total_Vs_Online_Hubs&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    totalVsOnlineAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=Total_Vs_Online_Appliances&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    wiFiAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=Wi-Fi_Appliances&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    dsmHubsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=DSM_Hubs_Chart&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    applianceTypesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=Appliance_Types&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    bleAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=BLE_Appliances_Chart&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    userRegistrationsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={c4eb1657-ceb6-4033-b494-e9f3405310a4}&action=embedview&Item=User_Registrations&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
  }
};

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { UserService } from '../services/user.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private userService: UserService,
    private titleService: Title,
    private location: Location) {
    this.titleService.setTitle("Access Denied");
  }

  ngOnInit(): void {
  }

  public logOut() {
    this.userService.logout();

  }

  public goBack() {
    this.location.historyGo(-2);
  }
}

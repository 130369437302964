import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBluetoothSearchCriteria } from '../types/iBluetoothSearchCriteria';

@Injectable({
  providedIn: 'root'
})
export class ApplianceService {

  constructor(private http: HttpClient) { }

  public GetBluetoothTypes(): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothTypes'));
  }

  public GetBluetoothModels(bluetoothType: string): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothModels/' + bluetoothType));
  }

  public GetBluetoothModelDetails(bluetoothModel: string): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothModelDetails/' + bluetoothModel));
  }

  public BluetoothSearch(bluetoothSearch: IBluetoothSearchCriteria): Observable<any>{
    let bluetooth = {
      ApplianceId: bluetoothSearch.bluetoothId,
      BluetoothModel: bluetoothSearch.productModel,
      BluetoothType: bluetoothSearch.productType,
      FirmwareVersion: bluetoothSearch.firmwareVersion,
    };
    return this.http.post<any>(this.endPoint('BluetoothSearch'), bluetooth);
  }

  public GetZigbeeTypes(): Observable<any>{
    return this.http.get<any>(this.endPoint('GetZigbeeTypes'));
  }

  private endPoint(action: string): string {
    return environment.apiBaseUrl + 'admin/appliance/' + action;
    
  }
}

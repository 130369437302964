import { Injectable } from '@angular/core';

import { MsalService } from '@azure/msal-angular';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { IUser } from '../types/iuser';
import { IUserPermission } from '../types/iuser-permission';
import { environment } from '../../environments/environment';

import { IdentityService } from './common/identity.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: IUser = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    groupId: null,
    groupTypeName: null,
    groupName: null,
    tenant: null,
    userIdExternal: null,
    isActive: null,
  };

  constructor(
    private msalService: MsalService,
    private http: HttpClient,
    private identityService: IdentityService) { }

  // Get the user name from the msal service token claims.
  public getUserName(): string {
    // If there are multiple accounts, loop through them until the account with the right policy is found
    // and extract the name from it.
    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      let givenName = account?.idTokenClaims['given_name'];
      let familyName = account?.idTokenClaims['family_name'];

      return givenName + " " + familyName;
    }
    return "";
  }

  public getExternalId(): string {
    const externalId = this.msalService.instance.getActiveAccount().idTokenClaims['oid'];
    return externalId;
  }

  public getAssociatedHub(userId: string) {
    return this.http.get<any>(this.endPoint('associatedHubs/' + userId));
  }

  // Log the user out.
  public logout() {
    this.identityService.isLoggedIn = false;
    localStorage.clear();
    sessionStorage.clear();
    this.msalService.logout();
  }

  public userSearch(
    firstName: string,
    lastName: string,
    email: string,
    groupTypeName: string,
    groupId: string,
    tenant: string,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean) {
    let params = new HttpParams();
    if (firstName) { params = params.append('firstName', firstName); }
    if (lastName) { params = params.append('lastName', lastName); }
    if (email) { params = params.append('email', encodeURIComponent(email)); }
    if (groupTypeName) { params = params.append('groupTypeName', groupTypeName); }
    if (groupId) { params = params.append('groupId', groupId); }
    if (tenant) { params = params.append('tenant', tenant); }
    if (searchLimit) { params = params.append('searchLimit', searchLimit); }
    if (pageNum) { params = params.append('pageNum', pageNum); }
    if (orderProperty) { params = params.append('orderProperty', orderProperty); }
    if (desc) { params = params.append('desc', desc); }

    return this.http.get<any>(this.endPoint('search'), { params });
  }

  public addUser(firstName: string, lastName: string, email: string, groupId: string, tenant: string, createProfileUrl: string) {

    //building a user object
    const addedUserDto = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        groupId: '',
        tenant: '',
      },
      createProfileUrl: '',
    };


    if (firstName) { addedUserDto.user.firstName = firstName; }

    if (lastName) { addedUserDto.user.lastName = lastName; }

    if (email) { addedUserDto.user.email = email; }

    if (groupId) { addedUserDto.user.groupId = groupId; }

    if (tenant) { addedUserDto.user.tenant = tenant; }

    if (createProfileUrl) { addedUserDto.createProfileUrl = createProfileUrl; }

    return this.http.post<any>(this.endPoint('add'), addedUserDto);
  }

  public updateUser(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    groupId: string,
    userIdExternal: string,
    tenant: string,
    productPermissions: string[]) {
    //building a user object
    let user = {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      groupId: null,
      userIdExternal: null,
      tenant: null,
      productPermissions: []
    };
    if (id) { user.id = id; }
    if (firstName) { user.firstName = firstName; }
    if (lastName) { user.lastName = lastName; }
    if (email) { user.email = email; }
    if (groupId) { user.groupId = groupId; }
    if (userIdExternal) { user.userIdExternal = userIdExternal; }
    if (tenant) { user.tenant = tenant; }
    if (productPermissions) { user.productPermissions = productPermissions; }

    return this.http.patch<any>(this.endPoint('update'), user);
  }

  public getUser(userId: string) {
    return this.http.get<any>(this.endPoint(userId));
  }

  public getExternalUser(userId: string) {
    return this.http.get<any>(this.endPoint('getExternal/' + userId));
  }

  public deleteUser(userId: string) {
    return this.http.delete<any>(this.endPoint(userId));
  }

  public getUserGroups() {
    return this.http.get<any>(this.endPoint('userGroups'));
  }

  public getMasterUserGroups() {
    return this.http.get<any>(this.endPoint('GetMasterUserGroups'))
  }

  public getTenants() {
    return this.http.get<any>(this.endPoint('tenants'));
  }

  public getMobileApps(tenant?: string) {
    let params = new HttpParams();
    if (tenant) { params = params.append('tenant', tenant); }
    return this.http.get<any>(this.endPoint('mobileApps'), { params });
  }

  public blockUser(userId: string, blockReason: string) {
    //building a user object
    let params = new HttpParams();
    if (userId) { params = params.append('userId', userId); }
    if (blockReason) { params = params.append('blockReason', blockReason); }

    return this.http.post<any>(this.endPoint('block'), null, { params });
  }

  public unblockUser(userId: string) {
    //building a user object
    let params = new HttpParams();
    if (userId) { params = params.append('userId', userId); }
    return this.http.post<any>(this.endPoint('block'), null, { params });
  }

  public getUserAnnouncements(
    application: string,
    userGroup: string,
    messageType: number,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean): Observable<any> {
    let params = new HttpParams();
    if (application) { params = params.append('application', application); }
    if (userGroup) { params = params.append('userGroup', userGroup); }
    if (messageType) { params = params.append('messageType', messageType); }
    if (searchLimit) { params = params.append('searchLimit', searchLimit); }
    if (pageNum) { params = params.append('pageNum', pageNum); }
    if (orderProperty) { params = params.append('orderProperty', orderProperty); }
    if (desc) { params = params.append('desc', desc); }

    return this.http.get<any>(this.endPoint('GetUserAnnouncements'), { params });
  }

  public getFunctionStatus(functionName: string): Observable<any> {
    let params = new HttpParams();
    if (functionName) { params = params.append('functionName', functionName); }

    return this.http.get<any>(this.endPoint('functionStatus'), { params });
  }

  public addUserAnnouncement(commSource: string, type: string, group: string, subject: string, message: string, application: string, userId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('commSource', commSource);
    params = params.append('type', type);
    params = params.append('group', group);
    params = params.append('subject', subject);
    params = params.append('message', message);
    params = params.append('application', application);
    params = params.append('userId', userId);

    return this.http.post<any>(this.endPoint('AddUserAnnouncement'), null, { params });
  }

  public getProductPermissions() {
    return this.http.get<any>(this.endPoint('Products'));
  }

  public getUserList(id: number, searchLimit: number, pageNum: number, orderProperty: string, desc: boolean) {
    let params = new HttpParams();
    params = params.append('announcementId', id);
    params = params.append('searchLimit', searchLimit);
    params = params.append('pageNum', pageNum);
    params = params.append('orderProperty', orderProperty);
    params = params.append('desc', desc);
    return this.http.get<any>(this.endPoint('GetUserList'), { params });
  }

  public resubmitAnnoucement(id: string, message: string, subject: string, application: string, commSource: number, messageType: number) {
    const announcementDto = {
      id: id,
      message: message,
      subject: subject,
      application: application,
      communicationSource: commSource,
      messageType: messageType,
    }
    return this.http.post<any>(this.endPoint('ResubmitUserAnnouncement'), announcementDto);
  }

  private endPoint(action: string): string {
    return environment.apiBaseUrl + 'admin/user/' + action;
  }

  private siblingOrderCompare(a: IUserPermission, b: IUserPermission) {
    return a.siblingOrder - b.siblingOrder;
  }
}

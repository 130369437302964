import { ErrorHandler, Injectable } from '@angular/core';

import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private loggingService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkLoadError = /ChunkLoadError/;
    if (chunkFailedMessage.test(error.message) || chunkLoadError.test(error.message)) {
      window.location.reload();
    }
    else {
      this.loggingService.logException(error);
    }
  }
}

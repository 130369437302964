import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRoute, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MsalService } from '@azure/msal-angular';

import { IdentityController } from '../services/api/identity/identity-controller.service';

@Injectable({
    providedIn: 'root'
})

export class AuthorizeGuard  {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private msalService: MsalService,
        private identityController: IdentityController
    ) { }

    async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
        await this.IsBlocked();
        return true;
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return true;
    }

    async IsBlocked(): Promise<boolean> {
        let user = this.msalService.instance.getActiveAccount();

        if (user) {
            let isBlocked = await this.identityController.IsBlockedAsync();
            if (isBlocked) {
                this.router.navigate(['/blocked'], { relativeTo: this.route });
            }
        }
        else {
            return false;
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firmwareBlob'
})
export class FirmwareBlobPipe implements PipeTransform {

  transform(value: string): any {
    if(!value){
      return;
    }
    return value.split("_").pop();
  }

}

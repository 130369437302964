import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  public resetPassword(userId?: string) {

    //building a user object
    const resetPassword = {
      userId: userId
    };

    return this.http.post<any>(this.endPoint('resetPassword'), resetPassword);
  }

  public contactUser(email: string, header: string, body: string) {

    //building a user object
    const contactUser = {
      email: '',
      header: '',
      body: '',
    };

    if (email) { contactUser.email = email; }

    if (header) { contactUser.header = header; }

    if (body) { contactUser.body = body; }

    return this.http.post<any>(this.endPoint('contactUser'), contactUser);
  }

  private endPoint(action: string): string {
    return environment.apiBaseUrl + 'admin/notification/' + action;
  }
}

<div>
    <div class='modal-header'>
        <h3 class='modal-title font-weight-bold m-0' id='modal-basic-title'>{{ username }}</h3>
        <button type='button' class='close' aria-label='Close' (click)='activeModal.dismiss()'>
            <span aria-hidden='true'>&times;</span>
        </button>
    </div>
    <div class='modal-body'>
        <div class='row'>
            <span class='ml-3 mb-2'><strong>Email Address: </strong>
                <a *ngIf='allowUserProfileRead' routerLink='../../../usermanagement/users/{{userDetails.id}}' (click)='activeModal.dismiss()'> {{ userDetails.emailAddress }} </a>
                <ng-container *ngIf="!allowUserProfileRead">{{ userDetails.emailAddress }}</ng-container>
            </span>
        </div>
        <br>
        <div class='row'>
            <span class='ml-3 mb-2'><strong>User Role:</strong> {{ userDetails.userGroup }} </span>
        </div>
        <div class='row my-4'>
            <button type="button" class="btn btn-primary ml-3" [disabled]="userDetails.emailAddress == null || userDetails.social" (click)="resetPassword()">Reset Password</button>
            <button type="button" class="btn btn-secondary ml-3" (click)="logout()">Logout</button>
        </div>
    </div>
    <div class='modal-footer'>
        <button type="button" class="btn btn-secondary" (click)="activeModal.close(false)">Close</button>
    </div>
</div>

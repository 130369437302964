<nav aria-label="breadcrumb" class="mt-3 pb-3">
  <div class="title">{{pageTitle}}</div>

  <div *ngIf="breadcrumbs.length > 0; then showBreadCrumb"></div>

  <ng-template #showBreadCrumb>
    <div>
      <ol class="breadcrumb">
        <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item"><a routerLink='{{crumb.routerLink}}'>{{crumb.routerText}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{pageName}}</li>
      </ol>
    </div>
  </ng-template>

</nav>

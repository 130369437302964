import { Component, Input } from '@angular/core';
import { IBreadCrumb } from '../../../types/ibread-crumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: IBreadCrumb[];
  @Input() pageName: string;
  @Input() pageTitle: string;

  constructor() { }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-blocked',
  templateUrl: './blocked.component.html',
  styleUrls: ['./blocked.component.css']
})
export class BlockedComponent implements OnInit {

  constructor(private userService: UserService,
    private titleService: Title) {
    this.titleService.setTitle("Blocked Account");
   }

  ngOnInit(): void {
  }

  public logOut() {
    this.userService.logout();
  }

}

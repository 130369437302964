<div class="card shadow h-100">
  <div *ngIf="isButton; else elseButton" class="card-header font-weight-bold "> 
    <p class=" float-left card-title align-text-bottom"> {{ title }} </p>
    <div class="float-right">
      <button ejs-button class="btn-text e-info" (click)="runTests()">Test All</button>
    </div>
  </div>
  <div class="nopadding" *ngIf="removePadding">
    <div *ngIf="isBusy; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock><em class="fa fa-spinner fa-pulse loaderpadding" aria-hidden="true"></em></ng-template>
    <ng-template #elseBlock>
      <ng-container #elseBlock *ngTemplateOutlet="contentTpl"></ng-container>
    </ng-template>
  </div>
  <div class="card-body" *ngIf="!removePadding">
    <div *ngIf="isBusy; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock><em class="fa fa-spinner fa-pulse" aria-hidden="true"></em></ng-template>
    <ng-template #elseBlock>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </ng-template>
  </div>
  <ng-template #contentTpl>
    <ng-content></ng-content>
  </ng-template>
</div>

<ng-template #elseButton>
  <div class="card-header font-weight-bold "> {{ title }} </div>
</ng-template>
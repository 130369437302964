import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationInsights, PageView } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {


  browser = this.getBrowserVersion();

  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true
      }
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetry();
  }

  logUserId(accountId: string) {
    this.appInsights.clearAuthenticatedUserContext();
    this.appInsights.setAuthenticatedUserContext(accountId);
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    // Experimental fix for instances of "not_specified" exception.  Make sure exception is of type Error before
    // calling app insights log.
    if (exception instanceof Error) {
      this.appInsights.trackException({ exception, severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }

  public updateAzureId(b2cId: string) {
    this.appInsights.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties.UserId = b2cId;
    });
  }

  getBrowserVersion() {

    const userAgent = navigator.userAgent; let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) { matchTest.splice(1, 1, tem[1]); }
    return matchTest.join(' ');
  }

  private loadCustomTelemetry() {
    this.appInsights.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties.ApplicationPlatform = 'Web';
      item.properties.ApplicationName = 'Admin Portal';
      this.appInsights.clearAuthenticatedUserContext();
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AddHeaderInterceptor implements HttpInterceptor {

  constructor(private loggingService: LoggingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({ headers: req.headers.append('Browser', this.loggingService.browser) });

    return next.handle(clonedRequest);
  }
}

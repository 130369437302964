import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IUserPermission } from 'src/app/types/iuser-permission';
import { environment } from 'src/environments/environment';

import { CacheService } from '../cache.service';
import { IdentityController } from '../api/identity/identity-controller.service';
import { IIdentityDto } from '../api/identity/iidentity-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(private http: HttpClient,
    private identityController: IdentityController,
    private cacheService: CacheService) { }

  public isLoggedIn: boolean = false;

  public synchronizeUser() {
    return this.http.patch<any>(this.endPoint('synchronizeUser'), null);
  }

  public async getIdentityAsync(): Promise<IIdentityDto> {
    let identityCache = this.cacheService.getIdentitiy();

    if (identityCache) {
      return JSON.parse(this.cacheService.getIdentitiy()) as IIdentityDto;
    }
    else {
      let identity = await this.identityController.GetIdentityAsync();
      this.cacheService.setIdentity(JSON.stringify(identity));
      return identity;
    }
  }

  public async hasPermissionAsync(permission: string): Promise<boolean> {
    let identity = await this.getIdentityAsync();
    return (identity?.groupPermissions?.indexOf(permission) ?? -1) !== -1;
  }

  public async hasProductPermissionAsync(permission: string): Promise<boolean> {
    let identity = await this.getIdentityAsync();
    return (identity?.userPermissions?.indexOf(permission) ?? -1) !== -1;
  }

  public async getUserPermissions(): Promise<IUserPermission[]> {

    let menuOptions: IUserPermission[] = [];

    if (this.cacheService.getUserPermissions() == null) {

      let permissions = await this.http.get<IUserPermission[]>(this.endPoint('getPermissions/GdPortalAdmin')).toPromise();
      permissions.sort((rec1, rec2) => this.siblingOrderCompare(rec1, rec2));
      for (const permission of permissions) {
        if (!permission.parentId) {
          var parent = permission;
          parent.children = permissions.filter(c => c.parentId === parent.code).sort((rec1, rec2) => this.siblingOrderCompare(rec1, rec2));
          parent.children.forEach(x => {
            x.children = permissions.filter(y => y.parentId === x.code);
          })
          menuOptions.push(parent);
        }
      }
      this.cacheService.setUserPermissions(JSON.stringify(menuOptions));
    }
    else {
      menuOptions = JSON.parse(this.cacheService.getUserPermissions());
    }

    return menuOptions;
  }

  public getAssociatedHub(userId: string) {
    return this.http.get<any>(this.endPoint('associatedHubs/' + userId));
  }

  public getExternalUser(): Promise<any> {
    return this.http.get<any>(this.endPoint('getExternal')).toPromise();
  }

  public resetPassword(): Observable<any> {
    return this.http.patch(this.endPoint('resetPassword'), null);
  }

  private siblingOrderCompare(a: IUserPermission, b: IUserPermission) {
    return a.siblingOrder - b.siblingOrder;
  }

  private endPoint(action: string): string {
    return environment.apiBaseUrl + 'identity/' + action;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent {

  @Input() title: string;
  @Input() isBusy = true;
  @Input() removePadding = false;
  @Input() isButton = false;
  @Output() runAll = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.title === 'Map')
    {
      this.removePadding = true;
    }
  }

  runTests() {
    this.runAll.emit();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private readonly userPermissionsKey: string = 'user.permissions';
  private readonly firmwarePermissionsKey: string = 'firmware.permissions';

  private readonly searchHubDetailsKey: string = 'search.hubDetails';  
  private readonly searchHubResultsKey: string = 'search.hubResults';
  private readonly searchHubPageResultsKey: string = 'search.hubPageResults';
  private readonly searchHubResultCountKey: string = 'search.hubResultCount';
  private readonly searchHubPageSettingsKey: string = 'search.hubPageSettings';
  private readonly searchHubOrderPropertyKey: string = 'search.hubOrderProperty';
  private readonly searchHubOrderDirectionKey: string = 'search.hubOrderDirection';

  private readonly searchRdeDetailsKey: string = 'search.rdeDetails';
  private readonly searchRdeResultsKey: string = 'search.rdeResults';

  private readonly searchUserDetailsKey: string = 'search.userDetails';
  private readonly searchUserResultsKey: string = 'search.userResults';

  private readonly searchGatewayDetailsKey: string = 'search.gatewayDetails';
  private readonly searchGatewayResultsKey: string = 'search.gatewayResults';

  private readonly searchGroupDetailsKey: string = 'search.groupDetails';
  private readonly searchGroupResultsKey: string = 'search.groupResults';

  private readonly addUserDetailsKey: string = 'user.addUserDetails';
  private readonly addUserResultsKey: string = 'user.addUserResults';

  private readonly editUserDetailsKey: string = 'user.editUserDetails';
  private readonly editUserResultsKey: string = 'user.editUserResults';

  private readonly blockUserDetailsKey: string = 'user.blockUserDetails';
  private readonly blockUserResultsKey: string = 'user.blockUserResults';

  private readonly productTypeSearchResultsKey: string = 'productType.searchResults';

  private readonly searchDirectApplianceDetailsKey: string = 'search.directApplianceDetails';
  private readonly searchDirectApplianceResultsKey: string = 'search.directApplianceResults';

  private readonly identityDetailsKey: string = 'identity.user';
  

  constructor() { }

  public getUserPermissions(): string {
    return localStorage.getItem(this.userPermissionsKey);
  }

  public setUserPermissions(permissions: string) {
    localStorage.setItem(this.userPermissionsKey, permissions);
  }

  public clearUserPermissions() {
    localStorage.removeItem(this.userPermissionsKey);
  }

  public clearFirmwarePermissions() {
    localStorage.removeItem(this.firmwarePermissionsKey);
  }

  public getSearchHubDetails(): string {
    return sessionStorage.getItem(this.searchHubDetailsKey);
  }

  public setSearchHubDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchHubDetailsKey, searchDetails);
  }

  public getSearchHubResults(): string {
    return sessionStorage.getItem(this.searchHubResultsKey);
  }

  public setSearchHubResults(searchResults: string) {
    sessionStorage.setItem(this.searchHubResultsKey, searchResults);
  }

  public getSearchHubPageResults(): string {
    return sessionStorage.getItem(this.searchHubPageResultsKey);
  }

  public setSearchHubPageResults(searchResultsPage: string) {
    sessionStorage.setItem(this.searchHubPageResultsKey, searchResultsPage);
  }

  public getSearchHubResultCount(): string {
    return sessionStorage.getItem(this.searchHubResultCountKey);
  }

  public setSearchHubResultCount(searchResultsCount: string) {
    sessionStorage.setItem(this.searchHubResultCountKey, searchResultsCount);
  }

  //cache endpoints for the search user method
  public getSearchUserDetails(): string {
    return sessionStorage.getItem(this.searchUserDetailsKey);
  }

  public setSearchUserDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchUserDetailsKey, searchDetails);
  }

  public getSearchUserResults(): string {
    return sessionStorage.getItem(this.searchUserResultsKey);
  }

  public setSearchUserResults(searchResults: string) {
    sessionStorage.setItem(this.searchUserResultsKey, searchResults);
  }

  //cache endpoints for the gateway user method
  public getSearchGatewayDetails(): string {
    return sessionStorage.getItem(this.searchGatewayDetailsKey);
  }

  public setSearchGatewayDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchGatewayDetailsKey, searchDetails);
  }

  public getSearchGatewayResults(): string {
    return sessionStorage.getItem(this.searchGatewayResultsKey);
  }

  public setSearchGatewayResults(searchResults: string) {
    sessionStorage.setItem(this.searchGatewayResultsKey, searchResults);
  }

  //cache endpoints for the add user method
  public getAddUserDetails(): string {
    return sessionStorage.getItem(this.addUserDetailsKey);
  }

  public setAddUserDetails(searchDetails: string) {
    sessionStorage.setItem(this.addUserDetailsKey, searchDetails);
  }

  public getAddUserResults(): string {
    return sessionStorage.getItem(this.addUserResultsKey);
  }

  public setAddUserResults(searchResults: string) {
    sessionStorage.setItem(this.addUserResultsKey, searchResults);
  }

  //cache endpoints for the edit user method
  public getEditUserDetails(): string {
    return sessionStorage.getItem(this.editUserDetailsKey);
  }

  public setEditUserDetails(searchDetails: string) {
    sessionStorage.setItem(this.editUserDetailsKey, searchDetails);
  }

  public getEditUserResults(): string {
    return sessionStorage.getItem(this.editUserResultsKey);
  }

  public setEditUserResults(searchResults: string) {
    sessionStorage.setItem(this.editUserResultsKey, searchResults);
  }

  //cache endpoints for the search group method
  public getSearchGroupDetails(): string {
    return sessionStorage.getItem(this.searchGroupDetailsKey);
  }

  public setSearchGroupDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchGroupDetailsKey, searchDetails);
  }

  public getSearchGroupResults(): string {
    return sessionStorage.getItem(this.searchGroupResultsKey);
  }

  public setSearchGroupResults(searchResults: string) {
    sessionStorage.setItem(this.searchGroupResultsKey, searchResults);
  }

  public getProductSearchResults(): string{
    return sessionStorage.getItem(this.productTypeSearchResultsKey);
  }

  public setProductSearchResults(productResults: string){
    sessionStorage.setItem(this.productTypeSearchResultsKey, productResults);
  }

  public getBlockUserDetails(): string {
    return sessionStorage.getItem(this.blockUserDetailsKey);
  }

  public setBlockUserDetails(blockUserDetailsKey: string) {
    sessionStorage.setItem(this.blockUserDetailsKey, blockUserDetailsKey);
  }

  public getSearchRdeDetails(): string {
    return sessionStorage.getItem(this.searchRdeDetailsKey);
  }

  public setSearchRdeDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchRdeDetailsKey, searchDetails);
  }

  public getSearchRdeResults(): string {
    return sessionStorage.getItem(this.searchRdeResultsKey);
  }

  public setSearchRdeResults(searchResults: string) {
    sessionStorage.setItem(this.searchRdeResultsKey, searchResults);
  }
  
  public getSearchDirectApplianceDetails(): string {
    return sessionStorage.getItem(this.searchDirectApplianceDetailsKey);
  }

  public setSearchDirectApplianceDetails(searchDetails: string) {
    sessionStorage.setItem(this.searchDirectApplianceDetailsKey, searchDetails);
  }

  public getSearchDirectApplianceResults(): string {
    return sessionStorage.getItem(this.searchDirectApplianceResultsKey);
  }

  public setSearchDirectApplianceResults(searchResults: string) {
    sessionStorage.setItem(this.searchDirectApplianceResultsKey, searchResults);
  }

  public getIdentitiy() : string {
    return sessionStorage.getItem(this.identityDetailsKey);
  }

  public setIdentity(identity: string){
    sessionStorage.setItem(this.identityDetailsKey, identity);
  }

  public clearCachedSearchResults() {
    sessionStorage.clear();
  }

  
}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';

import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { IdentityService } from './services/common/identity.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Loading...';
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private identityService: IdentityService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    // this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })

    //Subscribe to login token events.
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS, EventType),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        // Normal login flow.
        if (idtoken.acr === environment.b2cPolicies.authorities.signUpSignIn.name || idtoken.tfp === environment.b2cPolicies.authorities.signUpSignIn.name) {
          this.msalService.instance.setActiveAccount(payload.account);
        }

        // Password reset flow.
        if (idtoken.acr === environment.b2cPolicies.authorities.passwordReset.name || idtoken.tfp === environment.b2cPolicies.authorities.passwordReset.name) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN,
            scopes: []
          };

          this.login(signUpSignInFlowRequest);
        }

        if (result.eventType === EventType.LOGIN_SUCCESS) {
          this.identityService.synchronizeUser().toPromise();
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // If the user clicked forgot password, redirect to password reset flow.
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.b2cPolicies.authorities.passwordReset.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        };

        // If the user canceled the password reset flow, force a redirect back to the login screen.
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          let cancelResetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN,
            scopes: []
          };

          this.login(cancelResetPasswordFlowRequest);
        };
      });

    // Leaving in but commented out, useful for debugging b2c events as the user interacts with the UI.
    // this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
    //   console.log(result);
    // });
  }

  checkAndSetActiveAccount() {
    // Try and get the active account, if not set try and set it.  If there are multiple accounts, try
    // and match on the doman.
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let url = new URL(environment.authConfig.auth.authority);
      let domain = url.hostname;
      let account = this.msalService.instance.getAllAccounts().find(a => a.environment === domain);
      this.msalService.instance.setActiveAccount(account);
    }

    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      this.identityService.isLoggedIn = true;
    }
  }

  // Login redirect.
  login(userFlowRequest?: RedirectRequest) {
    this.msalService.loginRedirect({ ...userFlowRequest } as RedirectRequest);
  }

  // Unsubscribe to events.
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Map, HtmlMarker, AuthenticationType } from 'azure-maps-control';
import { environment } from 'src/environments/environment';
import { Maps, Marker, Zoom } from '@syncfusion/ej2-angular-maps';
Maps.Inject(Marker, Zoom);

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @ViewChild('map') public mapContainer: ElementRef;
  @Input() latitude: number;
  @Input() longitude: number;

  private map: any;

  public markerSettings = [{
    visible: true,
    enableDrag: false,
    fill: 'blue',
    height: 30,
    width: 25,
    dataSource: [
      { latitude: null, longitude: null}
    ],
  }]

  public zoomSettings = {
    enable: true,
    zoomFactor: 10,
    toolbars: ["Zoom", "ZoomIn", "ZoomOut", "Pan", "Reset"],
  };

  public centerPosition = {
    latitude: null,
    longitude: null,
  }

  public urlTemplate = environment.map.urlTemplate;

  ngOnInit() {
    this.centerPosition.latitude = this.latitude;
    this.centerPosition.longitude = this.longitude;
    this.markerSettings[0].dataSource[0].latitude = this.latitude;
    this.markerSettings[0].dataSource[0].longitude = this.longitude;
  }
}

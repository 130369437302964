import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
})
export class ErrorModalComponent {

  @Input() public errorDetails;
  @Input() public username;
  @Input() public email;
  @Input() public authenticationDetails;

  constructor(private errorModal: NgbActiveModal) { }

  get activeModal() {
    return this.errorModal;
  }
}

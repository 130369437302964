<div class="row">
    <div class="blocked-alert">
      <app-panel [isBusy]="false" [removePadding]="false">
        <div class="text-center">
          <h2>Access Denied</h2>
          <p>The account you are using does not have permission to view this page.</p>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <button ejs-button cssClass="e-info" class="btn-text" (click)="logOut()">
                Log Out
              </button>
            </div>
            <div class="col-lg-6">
              <button ejs-button cssClass="e-info" class="btn-text" (click)="goBack()">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </app-panel>
    </div>
  </div>
  
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byte'
})
export class BytePipe implements PipeTransform {

  transform(numberToTransform: number, decimals = 2): any {
    if (isNaN(numberToTransform)) { return null; }
    if (numberToTransform === null) { return null; }
    if (numberToTransform === 0) { return "0 Bytes"; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(numberToTransform) / Math.log(k));

    return parseFloat((numberToTransform / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
